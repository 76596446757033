import request from '@/request';
import request2 from '@/request/another';

//登录
export function login(params) {
  return request.post('/auth/dbLogin', params);
}

//获取用户信息，同时获取应用和对应环境
export function getUserInfo(params) {
  //pageNum, pageSize, searchKey, tagIds
  return request.post(`/user/getUserInfo`, params);
}

//获取应用详情
export function getInfoDetail(params) {
  return request.get('/app/getInfoDetail', { params });
}

//获取app列表
export function getAppList() {
  return request.get('/app/getAppList');
}

//获取app详细监控数据
export function getAppMonitorDetail(params) {
  return request.get('/app/getAppMonitorDetail', { params });
}

export function getBlockList(params) {
  return request.get('/monitoring/queryBlocks', { params });
}

export function getBlockMetrics(params) {
  return request.get('/monitoring/queryMetrics', { params });
}

//获取pod信息列表
export function getPodList(params) {
  return request.get('/app/getPodList', { params });
}

//获取ingress信息列表
export function getIngressList(params) {
  return request.get('/app/getIngressList', { params });
}

//删除ingress
export function deleteIngress(params) {
  return request.post('/app/deleteIngress', params);
}

//获取service信息列表
export function getServiceList(params) {
  return request.get('/app/getServiceList', { params });
}

//获取secret信息列表
export function getSecretList(params) {
  return request.get('/app/getSecretList', { params });
}

//获取ServiceAccount列表
export function getServiceAccountList(params) {
  return request.get('/app/getServiceAccountList', { params });
}

//获取configMap信息列表
export function getConfigMapList(params) {
  return request.get('/app/getConfigMapList', { params });
}

//获取deployment信息列表
export function getDeploymentList(params) {
  return request.get('/app/getDeploymentList', { params });
}

//获取pod日志列表
export function getPodLog(params) {
  return request.post('/app/getPodLog', params);
}

//获取application log
export function searchLogs(params) {
  return request.post('/app/log/search', params);
}

//获取用户集群列表
export function getClusters() {
  return request.get('/user/getClusters');
}

//根据cluster获取namespace
export function getNamespace(params) {
  return request.get('/user/getNamespaces', { params });
}

//获取pod详情
export function getPodDetail(params) {
  return request.get('/app/getPodDetail', { params });
}

//获取Deployment详情
export function getDeploymentDetail(params) {
  return request.get('/app/getDeploymentDetail', { params });
}

//获取Service详情
export function getServiceDetail(params) {
  return request.get('/app/getServiceDetail', { params });
}

//删除pod
export function deletePod(params) {
  return request.get('/app/deletePod', { params });
}

//删除deployment
export function deleteDeployment(params) {
  return request.get('/app/deleteDeployment', { params });
}

//重启deployment
export function restartDeployment(params) {
  return request.get('/app/restartDeployment', { params });
}

//调整deployment副本数-scale
export function scaleDeployment(params) {
  return request.get('/app/scaleDeployment', { params });
}

//删除service
export function deleteService(params) {
  return request.get('/app/deleteService', { params });
}

//删除configMap
export function deleteConfigMap(params) {
  return request.get('/app/deleteConfigMap', { params });
}

//删除secret
export function deleteSecret(params) {
  return request.get('/app/deleteSecret', { params });
}

//获取ConfigMap数据
export function getConfigMapData(params) {
  return request.get('/app/getConfigMapData', { params });
}

//获取Secret数据
export function getSecretData(params) {
  return request.get('/app/getSecretData', { params });
}

//编辑configMapData
export function editConfigMapData({ cluster, configMapName, data, namespace }) {
  return request.post(
    `/app/editConfigMapData?cluster=${cluster}&namespace=${namespace}&configMapName=${configMapName}`,
    data
  );
}

//新增audit
export function addAudit(code) {
  return request.post(`/base/addAudit?code=${code}`);
}

//编辑secretData
export function editSecretData({ cluster, secretName, data, namespace }) {
  return request.post(`/app/editSecretData?cluster=${cluster}&namespace=${namespace}&secretName=${secretName}`, data);
}

//创建secret
export function createSecret(params) {
  return request.post('/app/createSecret', params);
}

//获取未读消息数目
export function getMessageCount() {
  return request.get('/message/getCount');
}

//查看未读消息列表
export function getMessageList(params) {
  return request.get('/message/getList', { params });
}

//更改消息状态为已读
export function messageRead(params) {
  return request.post('/message/read', params);
}

//app添加/取消收藏
export function collect(params) {
  return request.post('/app/collect', params);
}

//编辑grafana监控数据设置
export function editGrafanaConfig(params) {
  return request.post('/app/editGrafanaConfig', params);
}

//获取审计日志类型
export function getAuditLogTypes() {
  return request.get('/system/getAuditLogTypes');
}

//获取审计日志
export function getAuditLog(params) {
  return request.get('/system/getAuditLog', { params });
}

//编辑namespace quota
export function editQuota(params) {
  return request.post('/app/editQuota', params);
}

//回滚 deployment
export function rolloutDeployment(params) {
  return request.post('/app/rolloutDeployment', params);
}

//批量删除消息
export function deleteMessage(params) {
  return request.post('/message/delete', params);
}

//获取环境信息
export function getEnvList() {
  return request.get('/base/getEnvList');
}

//获取用户信息
export function getUserList() {
  return request.get('/base/getUserList');
}







//新增应用
export function addApp(params) {
  return request.post('/system/addApp', params);
}

//获取用户信息
export function getUserInfoV1() {
  return request.get('/user/getUserInfoV1');
}

//修改用户密码
export function modifyPassword(params) {
  return request.post('/user/modifyPassword', params);
}

//获取App信息
export function getApp() {
  return request.get('/base/getAppList');
}

//获取App关联用户组信息
export function getAppAssocUserGroups(params) {
  return request.get('/base/app/getAssocUserGroups',{params});
}

//获取应用详情（关联环境及用户）
export function getAppDetail(params) {
  return request.get('/system/getAppAdmin', { params });
}

//============== console admin =====================
//用户 app和组的一些基本操作，对应BaseController

//编辑应用
export function editApp(params) {
  console.log(JSON.stringify(params));
  return request.post('/system/editApp', params);
}

//删除应用
export function deleteApp(params) {
  return request.post('/system/deleteApp', params);
}

//查询用户组列表
export function getUserGroupList() {
  return request.get('/base/user/group/list');
}

//添加用户组
export function addUserGroup(groupName) {
  return request.post(`/base/user/group/add/${groupName}`);
}

//更新用户组
export function updateUserGroup(params) {
  return request.post('/base/user/group/update',params);
}

//删除用户组
export function deleteUserGroup(groupId) {
  return request.post(`/base/user/group/delete/${groupId}`);
}

//查询用户组关联用户
export function getUsersByGroupId(params) {
  return request.get('/base/user/group/getAssocUsers',{params});
}

//更新用户组关联关系
export function updateUserGroupAssoc(params) {
  return request.post('/base/user/group/updateAssocUsers',params);
}

//获取用户组关联应用列表
export function getUserGroupAppList(params) {
  return request.get('/base/user/group/getAssocApps', { params });
}

//更新用户组关联APP关系
export function updateUserGroupAppAssoc(params) {
  return request.post('/base/user/group/updateAssocApps',params);
}

//编辑用户关联组列表
export function editUserGroupList(params) {
  return request.post('/base/user/updateAssocGroups', params);
}

//获取用户关联组列表
export function getUserAssocGroupList(params) {
  return request.get('/base/user/getUserAssocGroups', { params });
}

//============== console admin =====================

//查看当前用户的tag列表
export function getTagList() {
  return request.get('/tag/list');
}

//新增当前用户的tag
export function addTag(params) {
  return request.post('/tag/add', params);
}

//删除当前用户的tag
export function deleteTag(params) {
  return request.post('/tag/delete', params);
}

//为namespace添加tag
export function markTag(params) {
  return request.post('/tag/mark', params);
}

//获取Role列表
export function getRoleList() {
  return request.get('/base/getRoleList');
}

//根据角色id获取Permission列表
export function getPermissionList(params) {
  return request.get('/base/getPermissionList', { params });
}

//获取用户关联应用列表
export function getUserAppList(params) {
  return request.get('/system/getUserAppList', { params });
}





//编辑用户关联应用列表
export function editUserAppList(params) {
  return request.post('/base/user/editUserAppList', params);
}



//获取用户关联角色列表
export function getUserRoleList(params) {
  return request.get('/system/getUserRoleList', { params });
}

//编辑用户关联角色列表
export function editUserRoleList(params) {
  return request.post('/base/user/editUserRoleList', params);
}

//新增建议
export function addSuggest(params) {
  return request.post('/suggest/add', params);
}

//查看建议列表(查看当前用户)
export function querySuggestList() {
  return request.get('/suggest/list');
}

//获取用户建议列表(admin查看所有用户)
export function getSuggestList(params) {
  return request.post('/system/getSuggestList', params);
}

//获取版本列表
export function getVersionList() {
  return request.get('/base/getVersionList');
}

//获取EventInfo列表
export function getEventInfoList(params) {
  return request.get('/app/getEventInfoList', { params });
}

//============== CD Configuration =====================

//获取cd信息列表
export function getAppCDInfo(params) {
  return request.get('/cd/getAppCDInfo', { params });
}

//获取cd edit信息
export function getCDEditInfo(params) {
  return request.get('/cd/getCDEditInfo', { params });
}

//获取app cd review
export function getCDReview(params) {
  return request.post('/cd/getAppCDReview', params);
}

//argocd app部署
export function argocdDeployment(params) {
  return request.post('/cd/argocdDeployment', params);
}

//获取app部署状态
export function getAppCDStatus(params) {
  return request.get('/cd/getAppCDStatus', { params });
}

//argocd删除app
export function argocdDeleteDeploy(params) {
  return request.post('/cd/argocdDeleteDeploy', params);
}

//argocd app 编辑
export function argocdEditDeploy(params) {
  return request.post('/cd/argocdEditDeploy', params);
}

//argocd回滚
export function cdRollback(params) {
  return request.post('/cd/rollback', params);
}

//============== Ingress Configuration =====================

//获取ingress cd信息
export function getIngressCDInfo(params) {
  return request.get('/cd/getIngressCDInfo', { params });
}

//获取ingress cd review
export function getIngressCDReview(params) {
  return request.post('/cd/getIngressCDReview', params);
}

//argocd ingress部署
export function argocdDeployIngress(params) {
  return request.post('/cd/argocdDeployIngress', params);
}

//获取ingress部署状态
export function getIngressCDStatus(params) {
  return request.get('/cd/getIngressCDStatus', { params });
}

//获取cronjob
export function getCronJobList(params) {
  return request.get('/cd/getCronJobCDInfo', { params });
}

//删除cronjob
export function deleteCronjob(params) {
  return request.post('/cd/argocdDeleteCronJob', params);
}

export function getCronjobReview(params) {
  return request.post('/cd/getCronJobCDReview', params);
}

export function argocdDeployCronjob(params) {
  return request.post('/cd/argoCdDeployCronJob', params);
}

export function argocdEditCronJob(params) {
  return request.post('/cd/argocdEditCronJob', params);
}

export function getCronjobCDStatus(params) {
  return request.get('/cd/getCronJobCDStatus', { params });
}

//argocd删除ingress
export function argocdDeleteIngress(params) {
  return request.post('/cd/argocdDeleteIngress', params);
}

//argocd ingress 编辑
export function argocdEditIngress(params) {
  return request.post('/cd/argocdEditIngress', params);
}

//根据cluster获取host url
export function getHostUrl(params) {
  return request.get('/base/getHostUrl', { params });
}

//========================================

//添加镜像仓库配置
export function addRepositoryConfig(params) {
  return request.post('/cd/image/addRepositoryConfig', params);
}

//删除镜像仓库配置
export function deleteRepositoryConfig(params) {
  return request.post('/cd/image/deleteRepositoryConfig', params);
}

//编辑镜像仓库配置
export function editRepositoryConfig(params) {
  return request.post('/cd/image/editRepositoryConfig', params);
}

//查询镜像仓库配置
export function queryRepositoryConfigs(params) {
  return request.get('/cd/image/queryRepositoryConfigs', { params });
}

//Logging
export function getEsLoggingList(params) {
  return request.get('/app/logGetEsConfig', { params });
}

export function saveEsLogging(params) {
  return request.post('/app/logSaveEsConfig', params);
}

export function deleteLogging(params) {
  return request.post('/app/logDeleteConfig', params);
}

export function getSplunkLoggingList(params) {
  return request.get('/app/logGetSplunkConfig', { params });
}

export function getLoggingCount(params) {
  return request.get('/app/logGetLogCount', { params });
}

export function saveSplunkLogging(params) {
  return request.post('/app/logSaveSplunkConfig', params);
}

export function getChatUrl(cid) {
  return request.get(`/retrieval/${cid}/web`);
}


//==================cost model start======================
export function queryInfrastructureCosts() {
  return request2.get('/infrastructure-costs');
}

export function queryInfrasCostTrend(params) {
  return request2.get('/infrastructure-costs/cost-trend', { params });
}

export function queryApplicationCosts(params) {
  return request2.get('/application-costs', { params });
}

export function queryAppMapping() {
  return request2.get('/application-costs/dict-mapping');
}

export function queryAppCostTrend(params) {
  return request2.get('/application-costs/cost-trend', { params });
}

export function queryServiceCostsRank(params) {
  return request2.get('/service-costs/ranking', { params });
}

export function queryAccountMapping() {
  return request2.get('/account-costs/dict-mapping');
}

export function queryServiceCostsTrend(params) {
  return request2.get('/service-costs/cost-trend', { params });
}

export function queryAccountCosts(params) {
  return request2.get('/account-costs', { params });
}

export function queryServiceCostsAnalysis(params) {
  return request2.get('/service-costs/analysis', { params });
}

export function queryAppServiceCosts(appCode, params) {
  return request2.get(`/application-costs/${appCode}/service-costs/currentUser`, { params });
}

export function queryServiceCosts(params) {
  return request2.get('/service-costs', { params });
}

export function queryUntaggedServiceCosts(params) {
  return request2.get('/service-costs/untagged-services', { params });
}

export function queryAppAccounts(appCode) {
  return request2.get(`/application-costs/${appCode}/accounts`);
}

export function queryReports(params) {
  return request2.get(`/reports/uniform-costs/download`, { params });
}

export function queryTieredPrices() {
  return request2.get(`/cost-model/tiered-prices`);
}

export function queryQuestions() {
  return request2.get(`/cost-model/survey/questions`);
}

export function surveySubmit(params) {
  return request2.post('/cost-model/survey/submit', params);
}

//==================cost model end========================

//==================new cost model begin========================

export function queryUserGroups() {
  return request.get(`/user/group`);
}

export function queryInfrastructureCostsWithGroup(params) {
  return request2.post('/infrastructure-costs', params);
}

export function queryInfrasCostTrendWithGroup(params, body) {
  return request2.post('/infrastructure-costs/cost-trend', body, { params });
}

export function queryServiceCostsRankWithGroup(params, body) {
  return request2.post('/service-costs/ranking', body, { params });
}

export function queryServiceCostsWithGroup(params, body) {
  return request2.post('/service-costs', body, { params });
}

export function queryAppMappingWithGroup(body) {
  return request2.post('/application-costs/dict-mapping', body);
}

export function queryAppMappingWithCurrentUser() {
  return request2.get('/application-costs/dict-mapping/currentUser');
}

export function queryServiceCostsTrendWithGroup(params, body) {
  return request2.post('/service-costs/cost-trend', body, { params });
}

export function queryUntaggedServiceCostsWithGroup(params, body) {
  return request2.post('/service-costs/untagged-services', body, { params });
}

export function queryApplicationCostsWithGroup(params, body) {
  return request2.post('/application-costs', body, { params });
}

export function queryAppCostTrendWithGroup(params, body) {
  return request2.post('/application-costs/cost-trend', body, { params });
}

export function queryAccountMappingWithGroup(body) {
  return request2.post('/account-costs/dict-mapping', body);
}

export function queryReportsWithGroup(params, body) {
  console.log(JSON.stringify(params));
  return request2.post(`/reports/uniform-costs/download`, body, {
    params,
    responseType: 'blob'
  }).then(response => {
     // 检查 Blob 内容
    const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // 创建 Blob URL
    const url = window.URL.createObjectURL(blob);

    // 从 params 提取日期
    const startDate = params.start; // 确保参数名称与实际一致
    const endDate = params.end;     // 确保参数名称与实际一致
    const fileName = `AppCost-${startDate}-${endDate}.xlsx`;

    // 创建临时链接
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // 设置下载文件名

    // 触发下载
    document.body.appendChild(link);
    link.click();

    // 清除临时链接
    link.remove();
    window.URL.revokeObjectURL(url); // 释放 Blob URL

  }).catch(error => {
    console.error('下载失败:', error);
  });
}

export function queryAccountCostsWithGroup(params, body) {
  return request2.post('/account-costs', body, { params });
}

export function queryServiceCostsAnalysisWithGroup(params, body) {
  return request2.post('/service-costs/analysis', body, { params });
}

export function queryAppCostSummary(appCode, params) {
  return request2.get(`/application-costs/${appCode}/summary/currentUser`, { params });
}

export function queryAccountMappingWithCurrentUser() {
  return request2.get('/account-costs/dict-mapping/currentUser');
}

export function queryAppAccountsWithCurrentUser(appCode) {
  return request2.get(`/application-costs/${appCode}/accounts/currentUser`);
}

export function queryRulePrefixs() {
  return request2.get(`/rules/getPrefixs`);
}

export function queryConfig(params) {
  return request2.get(`/rules/getConfig`, { params });
}

export function updateCostConfig(params) {
  return request2.post('/rules/updateConfig', params);
}

export function queryConfigHistory(params) {
  return request2.get(`/rules/getConfigHistory`, { params });
}
//==================new cost model end========================

//==================mesh config begin========================
export function reviewMeshGeneratedConfig(params) {
  return request.post('/cd/canary/review', params);
}

export function removeCanary(params) {
  return request.post('/cd/argocdDeleteFlagger', params);
}

export function updateMeshConfig(params) {
  return request.post('/cd/canary/createOrEdit', params);
}

export function openPromotionGate(appId, serverName) {
  return request.put(`/cd/gate/promotion/open?serverName=${serverName}&appId=${appId}`);
}

export function openRollbackGate(appId, serverName) {
  return request.put(`/cd/gate/rollback/open?serverName=${serverName}&appId=${appId}`);
}

export function checkPromotionGate(params) {
  return request.get('/cd/gate/promotion/check', { params });
}

export function checkRollbackGate(params) {
  return request.get('/cd/gate/rollback/check', { params });
}

export function getMeshStatus(appId, serverName) {
  return request.get(`cd/canary/argocdStatus?serverName=${serverName}&id=${appId}`);
}


export function getSupportLog(params) {
  return request.post(`/my-support-bff/log/detail`,params);
}

export function getServiceId() {
  return request.get(`/my-support-bff/getServiceId`);
}

export function getActionByAction(params) {
  return request.get(`/my-support-bff/getActionByAction`,{params});
}

export function getLogByTraceId(params) {
  return request.post(`/my-support-bff/log/key`,params);
}

export function getSmo(params) {
  return request.post(`/my-support-bff/smo`,params);
}

export function queryVehicleData(params) {
  return request.post(`/my-support-bff/vehicle`, params);
}

export function queryVehicleOnlineProducts(params) {
  return request.post(`/my-support-bff/vehicle/online-products`, params);
}

export function queryVehicleOperateList(params) {
  return request.post(`/my-support-bff/vehicle/operationlist`, params);
}

//==================mesh config end========================