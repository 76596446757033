import { makeAutoObservable, runInAction } from 'mobx';

import {
  editUserAppList,
  editUserRoleList,
  editUserGroupList,
  getApp,
  getRoleList,
  getUserList,
  getUserGroupList,
} from '@/api';

class UserAdmin {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  userList = [];
  userGroupList = [];
  appsList = [];
  roleList = [];

  clearUserList() {
    this.userList = [];
  }

  //获取用户信息
  async getUserList() {
    let res;
    try {
      res = await getUserList();
      if (res?.data && res?.code === 200) {
        runInAction(() => {
          this.userList = res.data;
        });
      }
    } catch (err) {}

    return res;
  }

  //获取用户组信息
  async getUserGroupList() {
    let res;
    try {
      res = await getUserGroupList();
      if (res?.data && res?.code === 200) {
        runInAction(() => {
          this.userGroupList = res.data;
        });
      }
    } catch (err) {}

    return res;
  }

  //获取应用列表数据
  async getApp() {
    let res;
    try {
      res = await getApp();
      if (res?.code === 200 && res.data) {
        runInAction(() => {
          this.appsList = res.data;
        });
      }
    } catch (err) {}

    return res;
  }

  //获取权限列表数据
  async getRoleList() {
    let res;
    try {
      res = await getRoleList();
      if (res?.code === 200 && res.data) {
        runInAction(() => {
          this.roleList = res.data;
        });
      }
    } catch (err) {}

    return res;
  }

  //编辑用户关联应用列表
  async editUserAppList(params) {
    let res;
    try {
      res = await editUserAppList(params);
    } catch (err) {}

    return res;
  }

  //编辑用户关联角色列表
  async editUserRoleList(params) {
    let res;
    try {
      res = await editUserRoleList(params);
    } catch (err) {}

    return res;
  }

  //编辑用户关联组列表
  async editUserGroupList(params) {
    let res;
    try {
      res = await editUserGroupList(params);
    } catch (err) {}

    return res;
  }
}

export default new UserAdmin();
